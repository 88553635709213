<template>
  <div class="" v-if="isLoaded">
    <student-header />
    <div class="container-top">
      <div class="user-flow">
        <div class="user-splash"></div>
        <div class="row">
          <div class="user-form col-12">
            <form @submit.prevent="updateUserDetails">
              <h1 class="text-uppercase common-text text-center mb-5">
                EXCELERATE CAREER MATCHING
              </h1>
              <h4 class="welcome-desc font-weight-normal text-center mb-5">
                <strong
                  >Select the industries and positions you want to work
                  in</strong
                >
                <br />
                so we can match you with relevant companies and jobs. <br />
                <br />
                Select as many as you want. Click to select.
              </h4>

              <h4>Select industry areas</h4>

              <div class="profoile-jobs">
                <div
                  class="pj-selected"
                  v-if="userDetails.jobs ? userDetails.jobs.length != 0 : null"
                >
                  <span>Fields Selected: </span>
                  <ul>
                    <li v-for="(sj, i) in userDetails.jobs" :key="i">
                      <span
                        class="pink-color"
                        v-if="
                          sj.name.indexOf('All') != -1 && sj.name.length == 3
                        "
                        ><i
                          class="fas fa-times"
                          @click="removeSelectedJob(sj)"
                        ></i>
                        {{ sj.catgeory_name }} ({{ sj.name }}) |</span
                      >
                      <span v-else class="pink-color"
                        ><i
                          class="fas fa-times"
                          @click="removeSelectedJob(sj)"
                        ></i>
                        {{ sj.name }} |</span
                      >
                    </li>
                  </ul>
                </div>
                <div class="profoile-jobs">
                  <div class="pj-loading" v-show="!jobsData">
                    <i class="fas fa-circle-notch fa-spin"></i>
                  </div>
                  <div v-if="jobsData">
                    <ul class="job-ctg job_container">
                      <li v-for="(jobs, index) in jobsData" :key="index">
                        <a @click="toggleJobs"
                          >{{ jobs.name }}
                          <span>{{ jobs.description }}</span>
                        </a>
                        <job-lists
                          v-if="jobs.subjobs"
                          :selectedJobs="userDetails.jobs"
                          :jobsData="jobs.subjobs"
                          :category="jobs.id"
                          @jobSelection="handleJobChange"
                        ></job-lists>
                      </li>
                    </ul>
                    <div class="user-form-buttons">
                      <button
                        class="btn btn-primary ml-2 w-200 Talent-SelectIndustry-Next"
                        @click="saveJobs"
                        style=""
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import JobLists from '../../components/JobLists.vue';
import StudentHeader from '../../components/student/StudentHeader.vue';
import { storage } from '../../firebase';
export default {
  components: { StudentHeader, JobLists },
  data() {
    return {
      userDetails: null,
      jobsData: false,
    };
  },
  mounted() {
    this.loadJobs();
  },
  computed: {
    ...mapState(['user']),
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.userDetails = userInfo;
      }
      return true;
    },
    jobListLink: () => {
      return storage
        .refFromURL('gs://excelerate2020.appspot.com/Interestfields.json')
        .getDownloadURL();
    },
  },
  methods: {
    async loadJobs() {
      let jobListLink = await this.jobListLink;
      fetch(jobListLink, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          let jobs = [];
          result.forEach((parent) => {
            parent.field_one.forEach((stream) => {
              let ctgsSorted = stream.field_two.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              ctgsSorted.forEach((ctgs) => {
                let subjobs = [];
                ctgs.field_three.forEach((job) => {
                  subjobs.push({
                    __id: job.id,
                    name: job.name,
                    catgeory: ctgs.id,
                    catgeory_name: ctgs.name,
                    stream: stream.id,
                    parent: parent.id,
                  });
                });
                jobs.push({
                  id: ctgs.id,
                  name: ctgs.name,
                  subjobs: subjobs,
                  description: ctgs.description,
                });
              });
            });
          });
          this.jobsData = jobs.sort((a, b) => a.name.localeCompare(b.name));
        });
    },

    toggleJobs(e) {
      document.querySelectorAll('ul.job-ctg li').forEach((elem) => {
        elem.classList.remove('active');
      });
      e.target.parentNode.classList.toggle('active');
    },

    handleJobChange(job) {
      this.userDetails.jobs = job;
    },
    removeSelectedJob(job) {
      this.userDetails.jobs = this.userDetails.jobs.filter(
        (selected) => selected != job
      );
    },
    saveJobs() {
      this.$store.dispatch('updateUserDetails', this.userDetails);
      this.$router.push('/user/student-talent/matching');
    },
  },
};
</script>
<style scoped>
form {
  padding: 3% !important;
}
.user-flow {
  max-width: unset !important;
  border: unset !important;
  border-radius: 4px;
  background: #ffffff;
  -webkit-box-shadow: 0 4px 9px #dedddd;
  box-shadow: 0 4px 9px #dedddd;
  margin: 20px auto 0 auto;
  overflow: hidden;
}
.user-flow .user-col {
  position: relative;
  padding: 0 !important;
}
.user-enm-header {
  padding: 1rem 1rem 0.9rem;
}

.profoile-jobs {
  display: block;
  margin-bottom: 1rem;
}

.profoile-jobs ul.job-ctg {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.profoile-jobs ul.job-ctg li {
  float: left;
  margin-bottom: 0.4rem;
}

.profoile-jobs ul.job-ctg li a {
  border: 1px solid #d22d89;
  padding: 0.4rem 1.2rem;
  display: inline-block;
  color: #333;
  margin-right: 1rem;
  border-radius: 14px;
  cursor: pointer;
  position: relative;
  font-weight: 700;
}

ul.job-ctg li a span {
  display: block;
  font-size: 10px;
  color: #333;
  pointer-events: none;
  font-weight: 500;
}

ul.job-ctg li:hover a,
ul.job-ctg li.active a {
  color: #d22d89;
}

ul.job-ctg li.active a {
  padding: 0.4rem 2rem;
}

ul.job-lists {
  list-style: none;
  padding: 1rem;
  display: none;
}

.profoile-jobs ul.job-ctg li.active ul.job-lists {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 42rem;
  flex-wrap: wrap;
}

ul.job-lists li {
  width: 50%;
}

.dashboard .profile .profoile-jobs ul.job-ctg li.active {
  float: none;
  width: 100%;
}

ul.job-lists li label {
  color: #333;
  margin-bottom: 0;
}
.pj-selected {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.pj-selected span {
  color: #333;
  font-weight: 500;
  margin-right: 1rem;
}

.pj-selected ul {
  list-style: none;
  padding: 0;
}

.pj-selected ul li {
  float: left;
  margin-right: 1rem;
  color: #d22d89;
}

.pj-selected ul li i {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #d22d89;
  border-radius: 50%;
  color: #fff;
  font-size: 10px !important;
  padding: 0 5px;
  line-height: 1.5;
  cursor: pointer;
}

.pj-loading {
  width: 100%;
  height: 201px;
  background: rgb(235 235 235);
  top: 0;
  left: 0;
  position: relative;
  border-radius: 8px;
}

.pj-loading i {
  position: absolute;
  top: 46%;
  left: 46%;
  font-size: 36px !important;
  color: #d22d89;
  transform: translate(-50%, -50%);
}
.container-top {
  padding-top: 80px;
  padding-bottom: 80px;
}
.user-flow {
  box-shadow: none !important;
}
.job_container {
  margin-bottom: 4rem;
}

@media (max-width: 499px) {
  .job_container {
    margin-bottom: 2rem;
  }
}
</style>

<style>
@media (max-width: 767px) {
  #user-pages {
    background: #fff;
  }
  .user-flow .user-splash {
    display: none;
  }
  .container-top {
    padding-top: 40px !important;
    padding-bottom: 0px !important;
  }
  ul, ul li {
    max-width: 100%;
  }
}
</style>
